<!--
 * @Author: wuruoyu
 * @Description: 项目卡片
 * @Date: 2020-12-24 13:42:59
 * @LastEditors: wuruoyu
 * @LastEditTime: 2020-12-29 11:48:16
 * @FilePath: \huayun_platform\src\components\common\ProjectCard.vue
-->
<template>
  <div class="project-card">
    <div class="card-item" 
      v-for="(card,index) in list"
      @click="cardSel(card,index)"
      :class="{'active':activeIndex === index}"
      :key="card.code">
      <i class="el-icon-close" @click.stop="closeFn(card)"></i>
      <p>
        <i class="el-icon-coin icon"></i>
        <span class="title">{{card.name}}</span>
      </p>
      <p>
        <span class="info">
          项目人员：
          <span>{{card.count}}</span>
        </span>
        <span class="info">
          管理员：
          <span>{{card.adminCount}}</span>
        </span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex:0
    }
  },
  props:{
    list:{
      type:Array,
      default:() => []
    }
  },
  methods:{
    cardSel(card,index){
      this.activeIndex = index;
      this.$emit('cardSel',card)
    },
    closeFn(card){
      this.$emit('cardDel',card)
    }
  }
}
</script>

<style lang="scss" scoped>
  .project-card{
    height: 100%;
    display: flex;
    & > .card-item:not(:first-child){
      margin-left: 1.25rem;
    }
    .card-item{
      width: calc((100% - 5rem) / 5);
      background: #2F3238;
      border-radius: 4px;
      position: relative;
      padding: 1.25rem;
      border: 2px solid transparent;
      cursor: pointer;
      p{
        display: flex;
        align-items: center;
        & > .info:last-child{
          margin-left: 2rem;
        }
      }
      .icon{
        font-size: 2rem;
        color: #C9C9C9;
      }
      .title{
        font-size: 1.125rem;
        margin-left: 10px;
        color: #999999;
      }
      .info{
        font-size: 1rem;
        color: #999999;
      }
    }
    .active{
      border: 2px solid #3366FF;
      transition: all .3s ease-in;
      .icon{
        color: #3366FF !important;
      }
      .title{
        color: #ffffff !important;
      }
      .info{
        color: #ffffff !important;
      }
    }
    .el-icon-close{
      position: absolute;
      right: 5px;
      top: 5px;
      font-size: 1.1rem;
      font-weight: bold;
      color: #5C5D5E;
      cursor: pointer;
    }
  }
</style>
