<!--
 * @Author: wuruoyu
 * @Description: 综合能源服务
 * @Date: 2020-12-22 13:35:22
 * @LastEditors: wuruoyu
 * @LastEditTime: 2021-01-15 13:49:50
 * @FilePath: \huayun_platform\src\views\energyService\index.vue
-->
<template>
  <div class="energy-service">
    <div class="selbox">
      <el-cascader
        ref="mycascader"
        v-model="projectSel"
        :options="options"
        :props="selProps"
        @change="handleProSelectChange"
      ></el-cascader>
    </div>
    <div class="project-row">
      <ProjectCard 
        ref="projectcard"
        :list="cardList"
        @cardDel="carddelFn" 
        @cardSel="cardselFn">
      </ProjectCard>
    </div>
    <div class="main-container">
      <div class="project-list">
        <business-panel 
          v-for="(business, index) in businessList"
          :key="index"
          :business="business"
        ></business-panel>
      </div>
      <div class="login-user-info">
        <user-info :userInfo="userInfo"></user-info>
        <key-matters :keyMatters="keyMatters"></key-matters>
        <announcement-notice :noticeInfo="noticeInfo"></announcement-notice>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import ProjectCard from "@/components/common/ProjectCard"
import BusinessPanel from "@/components/BusinessPanel"
import UserInfo from "./UserInfo"
import KeyMatters from "./KeyMatters"
import AnnouncementNotice from "./AnnouncementNotice"
import { outProjectSelectApi, userProDisplayApi, userProDisplayEditApi } from "@/api/platform/project"
import { userPermissionList } from "@/api/commonApi"
import md5 from 'js-md5'
import { mapState } from "vuex"
export default {
  computed: {
    ...mapState(['serviceHeatingFlag']),
  },
  data() {
    return {
      selProps:{
        expandTrigger: 'hover',
        emitPath: true,
        value: 'code',
        label: 'name',
        children: 'child'
      },
      projectSel: '',
      options: [],
      cardList: [],
      currentNode: {},
      businessList: [],
      userInfo: {},
      keyMatters: [],
      noticeInfo: []
    }
  },
  mounted() {
    let userInfo = this.$store.state.userInfo
    if(userInfo && userInfo.id)  {
      this.getUserInfo(userInfo)
      this.getSelectData()
      this.getCardData()
    }
    window.addEventListener('message', ev=> {
      console.log('ev.origin', ev.origin)
      if('http://www.hyhncloud.cn' === ev.origin) {
      // if('http://localhost:8081' === ev.origin) {
        localStorage.setItem("userInfo", JSON.stringify(ev.data))
        this.$store.commit('TENANT_ID', ev.data.accountInfo.tenantId)
        this.$store.commit('SET_USER_INFO', ev.data)
        this.$store.commit('SET_USER_ID', ev.data.accountInfo.id)
        this.getUserInfo(ev.data)
        this.getSelectData()
        this.getCardData()
      }
    }, false)
  },
  methods: {
    //获取级联选择选项
    getSelectData() {
      let params = {
        userId: this.$store.state.userId,
        serviceCode:this.$store.state.serviceHeatingFlag
      }
      outProjectSelectApi(params).then(res => {
        this.options = res.result
      }).catch(e => {
        console.error(e.message)
      })
    },
    handleProSelectChange() {
      let nowSel = this.$refs.mycascader.getCheckedNodes()[0].data;
      let params = {
        userId: this.$store.state.userId,
        projectId: nowSel.id,
        code: this.serviceHeatingFlag,
        projectName: nowSel.name,
        operate: 'add'
      };
      userProDisplayEditApi(params).then(res => {
        this.getCardData()
        this.$refs.projectcard.activeIndex = 0
      }).catch(e => {
        console.error(e.message)
      })
    },
    getCardData() {
      let params = {
        userId: this.$store.state.userId,
        code: this.serviceHeatingFlag
      }
      userProDisplayApi(params).then(res => {
        if(res.result && res.result.length) {
          this.cardList = res.result
          this.currentNode = this.cardList[0]
          this.currentProject = this.cardList[0]
          this.getBusinessList()
        }
      }).catch(e => {
        console.error(e.message)
      })
    },
    carddelFn(data) {
      let params = {
        userId: this.$store.state.userId,
        projectId: data.id,
        code: this.serviceHeatingFlag,
        projectName: data.name,
        operate: 'delete'
      };
      userProDisplayEditApi(params).then(res => {
        this.getCardData()
      })
    },
    cardselFn(data) {
      this.currentNode = data
      this.currentProject = data
      this.getBusinessList()
    },
    getUserInfo(loginData) {
      if(loginData && loginData.accountInfo) {
        this.keyMatters = []
        this.noticeInfo = []
        this.userInfo = {}
        let { keyMatters, noticeInfo, accountInfo } = loginData
        this.keyMatters = keyMatters
        this.noticeInfo = noticeInfo
        this.userInfo = accountInfo
        let now = Date.now()
        let timeOnPage = now - this.userInfo.loginTime
        timeOnPage = (timeOnPage / 1000 / 60 / 60).toFixed(2)
        this.userInfo.timeOnPage = timeOnPage
      }
    },
    getBusinessList() {
      let params = {
        projectId: this.currentNode.id,
        userId: this.$store.state.userId
      }
      this.businessList = []
      userPermissionList(params).then(res => {
        if('SUCCESS' === res.status && res.result) {
          this.businessList = res.result
        }
      }).catch(e => {
        console.error(e.message)
      })
    },
    getCookie(name) {
      if (document.cookie.length > 0) {
        let c_start = document.cookie.indexOf(name + "=");
        if (c_start != -1) {
          c_start = c_start + name.length + 1;
          let c_end = document.cookie.indexOf(";", c_start);
          if (c_end == -1) {
            c_end = document.cookie.length;
          }
          return unescape(document.cookie.substring(c_start, c_end));
        }
      }
      return "";
    }
  },
  components: {
    ProjectCard,
    BusinessPanel,
    UserInfo,
    KeyMatters,
    AnnouncementNotice
  }
}
</script>

<style lang="scss" scoped>
.energy-service {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 1.25rem 0.5rem 1.25rem 1rem;
  &::-webkit-scrollbar {
    width: 0 !important;
  }
  .selbox {
    height: 3.125rem;
    .el-cascader {
      width: 17rem;
    }
    & /deep/ .el-input__inner {
      background: transparent;
      border: 1px solid rgb(148, 148, 148);
      color: #999999;
      height: 3.125rem;
    }
  }
  .project-row {
    margin: 1.25rem 0;
    height: 8.75rem;
  }
  .main-container {
    height: 47rem;
    margin-top: 1rem;
    display: flex;
    .project-list {
      flex: 7;
      margin-right: 1rem;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      &>div {
        background: #22242C;
        border-radius: 4px;
        &:not(:first-child) {
          margin-top: 2rem;
        }
      }
      &::-webkit-scrollbar {
        width: 0px !important;
      }
    }
    .login-user-info {
      flex: 3;
      display: flex;
      flex-direction: column;
      &>div {
        border-radius: 4px;
        background: #22242C;
        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      }
    }
  }
}
</style>
