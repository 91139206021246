<!--
 * @Descripttion: QR——下载应用
 * @version: 
 * @Author: year
 * @Date: 2020-09-15 13:45:48
 * @LastEditors: year
 * @LastEditTime: 2020-09-15 16:54:00
-->
<template>
  <div class="download-app">
    <div class="text">
     <slot></slot>
    </div>
    <span class="app-qr">
      <img :src="qrSrc" alt />
    </span>
  </div>
</template>
<script>
export default {
  props: ['qrSrc']
}
</script>

<style lang='scss' scoped>
.download-app {
  color: rgb(54, 54, 54);
  text-align: center;
  .text {
    margin-bottom: 30px;
  }
  .app-qr {
    img {
      width: 242px;
      height: 242px;
    }
  }
}
</style>
