<template>
  <div class="announcement-notice-container">
    <div class="title">公告通知</div>
    <div class="list-container">
      <div
        v-for="(notice, index) in noticeInfo"
        :key="index"
      >
        <div class="date">
          <div class="day">
            {{notice.day}}
          </div>
          <div class="month">
            {{notice.month}}月
          </div>
        </div>
        <div class="title" :title="notice.title">
          {{notice.title | wrapStrByByte(63)}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['noticeInfo']
}
</script>

<style lang="scss" scoped>
.announcement-notice-container {
  height: 17.375rem;
  padding: 1.375rem 1.625rem 1.375rem 1.25rem;
  .title {
    font-size: 0.875rem;
    color: #ffffff;
    font-weight: 700;
  }
  .list-container {
    height: 13.75rem;
    overflow: auto;
    &>div {
      height: 3.125rem;
      margin-top: 1.25rem;
      display: flex;
      .date {
        width: 4rem;
        background: #8B87EE;
        border-radius: 4px;
        text-align: center;
        padding-top: 0.4rem;
        .day {
          font-size: 1.125rem;
        }
        .month {
          font-size: 0.75rem;
        }
      }
      .title {
        width: calc(100% - 4rem);
        padding-left: 0.625rem;
        line-height: 3.125rem;
        font-size: 0.75rem;
        color: #cccccc;
        overflow: hidden;
      }
    }
  }
}
</style>
