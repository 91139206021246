/*
 * @Author: wuruoyu
 * @Description: 平台管理-项目组织
 * @Date: 2020-12-28 13:49:37
 * @LastEditors: wuruoyu
 * @LastEditTime: 2021-01-08 13:56:14
 * @FilePath: \huayun_platform\src\api\platform\project.js
 */
import { get,post } from "../request"

//用户项目下拉选择列表  ---新增加
export const outProjectSelectApi = (params) => get('/plat/user/outProjectSelect', params)

//用户项目下拉选择列表
export const projectSelectApi = (params) => get('/plat/user/projectSelect', params)

//查询用户项目标签块显示
export const userProDisplayApi = (params) => get('/plat/user/userProDisplay', params)

//用户项目标签显示块编辑
export const userProDisplayEditApi = (params) => post('/plat/user/userProDisplayEdit', params)

//初始化新增组织结构
export const initOrganAddApi = (params) => post('/plat/organ/initOrganAdd', params)

//获取组织树
export const organTreeApi = (params) => get('/plat/organ/organTree', params)

//单独新增组织节点
export const addNodeSingleApi = (params) => post('/plat/organ/addNodeSingle', params)

//设置节点管理人员
export const addNodeAdminApi = (params) => post('/plat/organ/addNodeAdmin', params)

//节点删除
export const deleteNodeApi = (params) => post('/plat/organ/deleteNode', params)

//更新节点名称
export const updateNodeNameApi = (params) => post('/plat/organ/updateNodeName', params)



// 通过组织节点，查询人员列表
export const queryUserByOrg = (params) => {
  return get('/plat/user/userForOrganization', params)
}

// 获取可分配用户列表信息
export const queryAssignableList = (params) => {
  return get('/plat/user/userForProAllot', params)
}

// 为项目分配人员
export const assignUserToPro = (params) => {
  return post('/plat/user/initPersonForProject', params)
}

// 将人员添加到组织节点
export const assignUserToOrg = (params) => {
  return post('/plat/user/addPersonForOrg', params)
}

// 将人员添加到组织节点
export const memberOrgAdjustment = (params) => {
  return post('/plat/user/orgMove', params)
}

// 将人员添加到组织节点
export const removeMemberFromOrg = (params) => {
  return post('/plat/user/userOrgDelete', params)
}