<template>
  <div class="user-info-container">
    <div class="basic-info">
      <div class="face-icon">
        <span>
          <i :class="'iconfont iconface'"></i>
        </span>
      </div>
      <div class="info">
        <div class="name">{{userInfo.name}}</div>
        <div class="account">账号ID: {{userInfo.account}}</div>
      </div>
    </div>
    <div class="login-info">
      <div class="duration">
        <div class="value">{{userInfo.timeOnPage}}</div>
        <div class="label">登录时长(h)</div>
      </div>
      <div class="last-time">
        <div class="value">{{userInfo.lastLoginTime}}</div>
        <div class="label">上次登录时间</div>
      </div>
      <div class="permission">
        <div class="value">{{userInfo.permission}}</div>
        <div class="label">权限</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['userInfo']
}
</script>

<style lang="scss" scoped>
.user-info-container {
  width: 100%;
  height: 11.75rem;
  padding: 2rem 2.5rem;
  display: flex;
  flex-direction: column;
  &>div {
    flex: 1;
  }
  .basic-info {
    display: flex;
    border-bottom: 1px solid #333438;
    .face-icon {
      width: 2.625rem;
      margin-right: 1.3rem;
      i {
        font-size: 2.25rem;
        color: #ffffff;
      }
    }
    .info {
      .name {
        font-weight: 700;
        color: #ffffff;
      }
      .account {
        margin-top: 0.5rem;
        font-size: 0.75rem;
        color: #999999;
      }
    }
  }
  .login-info {
    display: flex;
    &>div {
      text-align: center;
      font-size: 0.75rem;
      padding-top: 1.375rem;
      .value {
        height: 0.75rem;
        line-height: 0.75rem;
        color: #6699ff;
        margin-bottom: 0.875rem;
      }
      .label {
        color: #cccccc;
      }
    }
    .duration {
      flex: 1;
    }
    .last-time {
      flex: 2;
    }
    .permission {
      flex: 1;
    }
  }
}
</style>
