<template>
  <div class="key-matters-container">
    <div class="title">关键事项</div>
    <div class="matters-container">
      <div 
        v-for="(matter, index) in keyMatters"
        :key="index"
      >
        <div>{{matter.name}}</div>
        <div class="value">{{matter.num}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['keyMatters']
}
</script>

<style lang="scss" scoped>
.key-matters-container {
  height: 17rem;
  padding: 1.375rem 1.625rem 1.375rem 1.25rem;
  .title {
    font-size: 0.875rem;
    color: #ffffff;
    font-weight: 700;
  }
  .matters-container {
    margin-top: 1rem;
    height: 11.875rem;
    display: flex;
    flex-direction: column;
    &>div {
      flex: 1;
      background: #363C40;
      display: flex;
      justify-content: space-between;
      padding: 0 2.625rem 0 1.25rem;
      align-items: center;
      font-size: 0.875rem;
      color: #9ba3ad;
      &:not(:first-child) {
        margin-top: 0.625rem;
      }
      &:nth-child(1) {
        border-left: 4px solid #F97858;
      }
      &:nth-child(2) {
        border-left: 4px solid #007AFF;
      }
      &:nth-child(3) {
        border-left: 4px solid #33AD9B;
      }
      &:nth-child(4) {
        border-left: 4px solid #5CB034;
      }
      .value {
        color: #ffffff;
      }
      &:hover {
        background: #262930;
      }
    }
  }
}
</style>
