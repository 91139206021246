<!--
 * @Descripttion: 二维码dialog
 * @version: 
 * @Author: year
 * @Date: 2020-09-15 11:13:21
 * @LastEditors: year
 * @LastEditTime: 2020-09-17 14:30:06
-->
<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    :modal-append-to-body='false'
    width="500px"
    center
    custom-class="public-dialog QRDialog"
  >
    <slot></slot>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="clickBtn()">确 认</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    title: String,
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    clickBtn() {
      if (this.type == "push") {
        this.submitPublic();
      }
      this.dialogVisible = false;
    },

    submitPublic() {
      let pushInfo = this.$children[0].$children[1].$children[0];
      let messageNote = pushInfo.messageNote;
      let abnormalyNote = pushInfo.abnormalyNote;
      let energyReport = pushInfo.energyReport;
      let params = {
        messageNote,
        abnormalyNote,
        energyReport,
      };
      console.log(params);
    },
  },
  components: {},
};
</script>

<style lang='scss' >
.QRDialog {
  .el-button {
    width: 81px;
    height: 36px;
    border-radius: 0px;
    background-color: rgb(16, 140, 238);
    margin-bottom: 10px;
  }
}
</style>
