/*
 * @Descripttion: 
 * @version: 
 * @Author: year
 * @Date: 2020-12-28 19:08:18
 * @LastEditors: year
 * @LastEditTime: 2020-12-30 19:37:56
 */
import { get, post } from './request'

export const getUserInfo = (params) => {
  return get('/plat/user/getUser', params)
}

//获取用户组织信息
export const getUserOrgApi = (params) => get('/plat/user/getUserOrg', params)

//获取用户项目下拉选择列表
export const projectSelectApi = (params) => get('/plat/user/projectSelect', params)

//获取用户项目列表信息
export const userProjectListApi = (params) => get('/plat/user/userProjectList', params)

//获取项目下拉框信息
export const addUserProjectListApi = (params) => get('/plat/user/addUserProjectList', params)

//通过项目id获取项目信息
export const projectInfoApi = (params) => get('/plat/user/projectInfo', params)

//获取组织树
export const getOrganTreeApi = (params) => get('/plat/organ/organTree', params)

// 登录人员所拥有的权限列表
export const userPermissionList = (params) => get('/plat/user/permissionByPro', params)

//获取节点人员列表
export const userForOrganizationApi = (params) => get('/plat/user/userForOrganization', params)


